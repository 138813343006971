import { Component, EventEmitter, Output } from '@angular/core';
import { TvhLanguage } from '../shared/constants/language.constant';

@Component({
  selector: 'tvh-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent {
  @Output() readonly langSelected: EventEmitter<TvhLanguage> = new EventEmitter<TvhLanguage>();
  lang = TvhLanguage;

  selectLang(lang: TvhLanguage): void {
    this.langSelected.emit(lang);
  }
}
