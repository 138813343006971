import { Component, Input } from '@angular/core';
import { TvhUserTransactionLog } from '../../models/user-transaction-log';

@Component({
  selector: 'tvh-user-transaction-card',
  templateUrl: './user-transaction-card.component.html',
  styleUrls: ['./user-transaction-card.component.scss'],
})
export class UserTransactionCardComponent {
  @Input() transaction!: TvhUserTransactionLog;
}
