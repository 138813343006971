import { CoolSocialLoginButtonsModule } from '@angular-cool/social-login-buttons';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN } from '@angular-ru/ngxs/storage';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { default as localeNl } from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { Amplify } from 'aws-amplify';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { cognitoClientId, cognitoDomain, cognitoPoolId, cognitoRegion } from '../aws-exports';
import { PaymentConfigurationState } from './admin/store/payment-configuration.state';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { ContactState } from './contact/store/contact.state';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { LocationDetailState } from './location/modules/location-detail/store/location-detail.state';
import { MachineState } from './location/modules/machine/store/machine.state';
import { LocationState } from './location/store/location.state';
import { AddCreditComponent } from './payment/components/add-credit/add-credit.component';
import { CustomAmountBottomSheetComponent } from './payment/components/custom-amount-bottom-sheet/custom-amount-bottom-sheet.component';
import { PaymentErrorComponent } from './payment/components/payment-error/payment-error.component';
import { PaymentLandingComponent } from './payment/components/payment-landing/payment-landing.component';
import { PaymentSuccessComponent } from './payment/components/payment-success/payment-success.component';
import { CustomAmountPaymentState } from './payment/store/custom-amount-payment.state';
import { PaymentLandingState } from './payment/store/payment-landing.state';
import { PaymentState } from './payment/store/payment.state';
import { ProfileState } from './profile/store/profile.state';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { SharedModule } from './shared/shared.module';
import { AppConfigState } from './shared/store/app-config.state';
import { AuthState } from './shared/store/auth.state';
import { LangState } from './shared/store/lang.state';
import { LoadingState } from './shared/store/loading.state';
import { TitleState } from './shared/store/title.state';
import { UserTransactionCardComponent } from './transactions/components/user-transaction-card/user-transaction-card.component';
import { UserTransactionsState } from './transactions/store/user-transactions.state';
import { UserTransactionsComponent } from './transactions/user-transactions.component';

const uiUrl = `${location.origin}${location.pathname}`.replace(/\/$/, '');

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: cognitoPoolId,
      userPoolClientId: cognitoClientId,
      identityPoolId: '',
      allowGuestAccess: false,
      loginWith: {
        oauth: {
          domain: cognitoDomain,
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [uiUrl],
          redirectSignOut: [uiUrl],
          responseType: 'code',
        },
      },
    },
  },
});

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', `.json?tstamp=${new Date().getTime()}`);

const LOCALE_PROVIDERS = [{ provide: LOCALE_ID, useValue: 'nl-BE' }];
registerLocaleData(localeNl, 'nl-BE');

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    LanguageSelectComponent,
    UserTransactionsComponent,
    UserTransactionCardComponent,
    AddCreditComponent,
    PaymentLandingComponent,
    PaymentErrorComponent,
    PaymentSuccessComponent,
    CustomAmountBottomSheetComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoolSocialLoginButtonsModule,
    NgxsModule.forRoot([
      AuthState,
      AppConfigState,
      ContactState,
      LangState,
      LoadingState,
      LocationDetailState,
      LocationState,
      MachineState,
      PaymentState,
      PaymentConfigurationState,
      PaymentLandingState,
      CustomAmountPaymentState,
      ProfileState,
      TitleState,
      UserTransactionsState,
    ]),
    NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
      progressBar: true,
    }),
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: window.location.hostname,
      },
      palette: {
        popup: {
          background: '#6f62c5',
        },
        button: {
          background: '#9ff6fb',
        },
      },
      position: 'top-right',
      theme: 'edgeless',
      type: 'info',
    }),
    AmplifyAuthenticatorModule,
  ],
  providers: [
    ...LOCALE_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
