import { Computed, DataAction, Payload, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select, State } from '@ngxs/store';
import { Observable } from 'rxjs';

export class SetTitle {
  static readonly type = '[Title] Set';
  constructor(public title: string) {}
}

@StateRepository()
@State<string>({
  name: 'title',
  defaults: '',
})
@Injectable()
export class TitleState extends NgxsImmutableDataRepository<string> {
  @Select(TitleState) title$: Observable<string>;

  @Computed() get title(): string {
    return this.snapshot;
  }

  constructor(
    private readonly platformTitle: Title,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  @DataAction() setTitle(@Payload('title') title: string): void {
    const translated = this.translate.instant(title);
    this.platformTitle.setTitle(`${translated} | Qreddi`);
    this.ctx.setState(() => translated);
  }
}
