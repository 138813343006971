import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  static trackById = (index: null, item: { id: number | string }): number | string => item.id;

  static trackByIndex = (index: null): number | null => index;

  static trackByNumber = (index: null, item: { number: number | string }): number | string => item.number;
}
