export enum TvhPaymentStatus {
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  PAID = 'PAID',
  CREATED = 'CREATED',
}
