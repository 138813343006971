import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PaymentConfigurationState } from '../../../admin/store/payment-configuration.state';
import { CustomAmountPaymentState } from '../../store/custom-amount-payment.state';

@Component({
  selector: 'tvh-custom-amount-bottom-sheet',
  templateUrl: './custom-amount-bottom-sheet.component.html',
  styleUrls: ['./custom-amount-bottom-sheet.component.scss'],
})
export class CustomAmountBottomSheetComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    readonly paymentConfig: PaymentConfigurationState,
    private readonly customAmountState: CustomAmountPaymentState,
    private readonly bottomSheetRef: MatBottomSheetRef<CustomAmountBottomSheetComponent>,
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      amount: new UntypedFormControl('', [Validators.required, Validators.min(this.paymentConfig.minimumAmount)]),
    });
  }

  cancel = (): void => {
    this.bottomSheetRef.dismiss();
    this.customAmountState.reset();
  };

  startPayment = (): void => {
    this.bottomSheetRef.dismiss();
    this.customAmountState.startCustomPayment();
  };
}
