import { Computed, DataAction, Payload } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { PageEvent } from '@angular/material/paginator';
import { Draft, Immutable, produce } from 'immer';
import { isNil, isNumber, isString, pickBy } from 'lodash-es';
import { BasePaginatedApiService } from '../services/base-paginated-api.service';
import { NavigateService } from '../services/navigate.service';

export interface BasePaginatedTableStateModel<T> {
  pageItems: Array<T>;
  paginatorIndex: number;
  pageSize: number;
  totalCount: number;
  filterForm: {
    model: {
      [key: string]: unknown;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
}

export abstract class BasePaginatedTableState<T> extends NgxsDataRepository<BasePaginatedTableStateModel<T>> {
  abstract searchOnReset: boolean;

  @Computed() get pageItems(): Immutable<Array<any>> {
    return this.snapshot.pageItems;
  }

  @Computed() get totalCount(): number {
    return this.snapshot.totalCount;
  }

  @Computed() get paginatorIndex(): number {
    return this.snapshot.paginatorIndex;
  }

  @Computed() get pageSize(): number {
    return this.snapshot.pageSize;
  }

  protected constructor(
    protected readonly api: BasePaginatedApiService<T>,
    protected readonly navigate: NavigateService,
  ) {
    super();
  }

  getDataFromBackend(event?: PageEvent): void {
    // TODO error handling?
    this.api.getPage(event?.pageIndex, event?.pageSize, this.filters).subscribe((page) => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.pageItems = page.items as Array<Draft<T>>;
          draft.pageSize = page.pageSize;
          draft.paginatorIndex = page.pageIndex - 1;
          draft.totalCount = page.totalCount;
        }),
      );
    });
  }

  @DataAction() changePage(@Payload('event') event: PageEvent): void {
    this.getDataFromBackend(event);
  }

  @DataAction() resetFilters(): void {
    this.reset();
    if (this.searchOnReset) {
      setTimeout(() => {
        this.getDataFromBackend();
      });
    }
  }

  @DataAction() search(): void {
    this.getDataFromBackend();
  }

  @DataAction() cancel(): void {
    this.navigate.toManagement();
  }

  @Computed() protected get filters(): { [key: string]: unknown } {
    // Filter values, with all undefined, null and empty string values removed
    return pickBy(this.snapshot.filterForm.model, this.notEmpty);
  }

  private readonly notEmpty = (val: string | number | undefined | null): boolean => {
    if (isNumber(val)) {
      return true;
    }
    if (isString(val)) {
      return !!val.length;
    }

    return !isNil(val);
  };
}
