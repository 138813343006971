export enum TvhMachineType {
  DRYER = 'DRYER',
  IRONING_ROLL = 'IRONING_ROLL',
  WASHING_MACHINE = 'WASHING_MACHINE',
  LIGHT = 'LIGHT',
  DOOR = 'DOOR',
  CAR_WASH = 'CAR_WASH',
  VACUUM_CLEANER = 'VACUUM_CLEANER',
  TOKEN_DISPENSER = 'TOKEN_DISPENSER',
  VENDING_MACHINE = 'VENDING_MACHINE',
  BREAD_MACHINE = 'BREAD_MACHINE',
  COFFEE_DISPENSER = 'COFFEE_DISPENSER',
  DRINK_MACHINE = 'DRINK_MACHINE',
  TICKET_MACHINE = 'TICKET_MACHINE',
}
