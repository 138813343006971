import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUtilsService {
  static SKIP_ERROR_INTERCEPTOR_HEADER = 'X-TVH-SKIP-ERROR-INTERCEPTOR';
  static SKIP_LOADING_INTERCEPTOR_HEADER = 'X-TVH-SKIP-LOADING-INTERCEPTOR';
  private static readonly HEADER_VALUE = 'tvh';

  static skipErrorInterceptor(headers?: HttpHeaders): HttpHeaders | undefined {
    let newHeaders = headers ?? new HttpHeaders();
    newHeaders = newHeaders.set(ApiUtilsService.SKIP_ERROR_INTERCEPTOR_HEADER, ApiUtilsService.HEADER_VALUE);

    return newHeaders;
  }

  static skipLoadingInterceptor(headers?: HttpHeaders): HttpHeaders | undefined {
    let newHeaders = headers ?? new HttpHeaders();
    newHeaders = newHeaders.set(ApiUtilsService.SKIP_LOADING_INTERCEPTOR_HEADER, ApiUtilsService.HEADER_VALUE);

    return newHeaders;
  }
}
