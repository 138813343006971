import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
} from '@angular/material-luxon-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MachineCardComponent } from '../location/modules/machine/components/machine-card/machine-card.component';
import { MachineDetailCardComponent } from '../location/modules/machine/components/machine-detail-card/machine-detail-card.component';
import { MachineIconsComponent } from './components/machine-icons/machine-icons.component';
import { WrapperTableComponent } from './components/wrapper-table/wrapper-table.component';
import { DistancePipe } from './pipes/distance.pipe';
import { EuroPipe } from './pipes/euro.pipe';
import { MachineTypePipe } from './pipes/machine-type.pipe';

const sharedModules = [
  CommonModule,
  HttpClientModule,
  TranslateModule,
  ReactiveFormsModule,
  // Material modules
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
  MatRippleModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatToolbarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatBadgeModule,
  MatTableModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatDialogModule,
  MatBottomSheetModule,
];

const datePickerProviders = [
  {
    provide: DateAdapter,
    useClass: LuxonDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
];

const sharedPipes = [DistancePipe, EuroPipe, MachineTypePipe];

const sharedComponents = [
  MachineCardComponent,
  MachineDetailCardComponent,
  MachineIconsComponent,
  WrapperTableComponent,
];

const sharedDirectives = [];

@NgModule({
  declarations: [...sharedComponents, ...sharedDirectives, ...sharedPipes],
  imports: [...sharedModules],
  exports: [...sharedComponents, ...sharedDirectives, ...sharedModules, ...sharedPipes],
  providers: [CurrencyPipe, DecimalPipe, ...sharedPipes, ...datePickerProviders],
})
export class SharedModule {}
