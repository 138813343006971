import { Computed, DataAction, Payload, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { produce } from 'immer';
import { ProfileState } from '../../profile/store/profile.state';
import { NavigateService } from '../../shared/services/navigate.service';
import { TvhPaymentStatus } from '../constants/payment-status';
import { TvhPayment } from '../models/payment';
import { PaymentService } from '../services/payment.service';

export interface PaymentLandingStateModel {
  paymentId: number;
  pending: boolean;
}

@StateRepository()
@State<PaymentLandingStateModel>({
  name: 'paymentLanding',
  defaults: {
    paymentId: 0,
    pending: true,
  },
})
@Injectable()
export class PaymentLandingState extends NgxsImmutableDataRepository<PaymentLandingStateModel> {
  constructor(
    private readonly navigate: NavigateService,
    private readonly paymentSvc: PaymentService,
    private readonly profileState: ProfileState,
  ) {
    super();
  }

  @Computed() get isPending(): boolean {
    return this.snapshot.pending;
  }

  @DataAction() setPaymentId(@Payload('paymentId') paymentId: number): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.paymentId = paymentId;
        draft.pending = true;
      }),
    );
    this.checkPaymentStatus();
  }

  @DataAction() markResolved(): void {
    this.ctx.setState(
      produce(this.getState(), (draft) => {
        draft.pending = false;
      }),
    );
  }

  private checkPaymentStatus(): void {
    this.paymentSvc.getPayment(this.snapshot.paymentId, true).subscribe((p: TvhPayment) => {
      switch (p.status) {
        case TvhPaymentStatus.CANCELED:
        case TvhPaymentStatus.EXPIRED:
        case TvhPaymentStatus.FAILED:
          this.paymentError();
          break;
        case TvhPaymentStatus.PAID:
        case TvhPaymentStatus.CREATED:
          this.paymentSuccess();
          break;
        default:
          setTimeout(() => {
            this.checkPaymentStatus();
          }, 1000);
      }
    });
  }

  private paymentError(): void {
    this.markResolved();
    this.navigate.toPaymentError(this.snapshot.paymentId);
  }

  private paymentSuccess(): void {
    this.profileState.refreshBalance();
    this.markResolved();
    this.navigate.toPaymentSuccess(this.snapshot.paymentId);
  }
}
