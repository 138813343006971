import { StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

export interface ContactStateModel {
  contactForm: {
    model: {
      question: string;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
}

@StateRepository()
@State<ContactStateModel>({
  name: 'contact',
  defaults: {
    contactForm: {
      model: {
        question: '',
      },
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable()
export class ContactState extends NgxsImmutableDataRepository<ContactStateModel> {}
