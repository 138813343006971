<mat-card class="transaction-card mat-elevation-z3">
  <div class="container">
    <mat-card-header>
      <mat-card-title>{{ 'ADMIN.TRANSACTIONS.STATUS_' + transaction.action | translate }} </mat-card-title>
      <mat-card-subtitle>{{ transaction.locationName }}</mat-card-subtitle>
      <mat-card-subtitle class="font-light">{{ transaction.date | date: 'medium' }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-header class="text-right">
      <mat-card-title
        [class.amount-negative]="transaction.amount < 0"
        [class.amount-positive]="transaction.amount > 0"
        >{{ transaction.amount | euro }}</mat-card-title
      >
      <mat-card-subtitle *ngIf="transaction.machineNr">Machine {{ transaction.machineNr }}</mat-card-subtitle>
    </mat-card-header>
  </div>
</mat-card>
