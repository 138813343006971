import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PaymentConfigurationState } from '../../../admin/store/payment-configuration.state';
import { LocationState } from '../../../location/store/location.state';
import { UtilService } from '../../../shared/services/util.service';
import { CustomAmountPaymentState } from '../../store/custom-amount-payment.state';
import { PaymentState } from '../../store/payment.state';
import { CustomAmountBottomSheetComponent } from '../custom-amount-bottom-sheet/custom-amount-bottom-sheet.component';

@Component({
  selector: 'tvh-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.scss'],
})
export class AddCreditComponent {
  bonusAmounts = [1, 2];
  utils = UtilService;

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private readonly customAmount: CustomAmountPaymentState,
    private readonly location: LocationState,
    private readonly payment: PaymentState,
    readonly paymentConfig: PaymentConfigurationState,
  ) {}

  addBonus = (multiplier: number): void => {
    const amount = this.paymentConfig.bonusBaseAmount * multiplier;
    this.payment.startPayment(amount);
  };

  addOther = (): void => {
    this.bottomSheet.open(CustomAmountBottomSheetComponent);
  };

  cancel = (): void => {
    this.location.toHome();
  };
}
