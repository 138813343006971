import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigState } from '../../shared/store/app-config.state';
import { TvhPaymentConfig } from '../models/payment-config';

@Injectable({
  providedIn: 'root',
})
export class PaymentConfigurationApiService {
  constructor(
    private readonly config: AppConfigState,
    private readonly http: HttpClient,
  ) {}

  getPaymentConfig(): Observable<TvhPaymentConfig> {
    return this.http.get<TvhPaymentConfig>(`${this.config.apiPath}/admin/parameters`);
  }

  updatePaymentConfig(config: TvhPaymentConfig): Observable<TvhPaymentConfig> {
    return this.http.put<TvhPaymentConfig>(`${this.config.apiPath}/admin/parameters`, config);
  }
}
