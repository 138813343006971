<mat-card class="machine-card" [class.disabled]="isDisabled" (click)="clicked()">
  <mat-card-content
    [matBadge]="machine.number"
    matBadgeColor="accent"
    matBadgeOverlap
    matBadgePosition="below after"
    matBadgeSize="large"
  >
    <tvh-machine-icons
      [machine]="machine"
      [showMachineStatus]="showMachineStatus"
      [failedState]="failedState"
    ></tvh-machine-icons>
  </mat-card-content>
</mat-card>
