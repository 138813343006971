import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euro',
})
export class EuroPipe implements PipeTransform {
  private readonly CURRENCY_FORMAT: string = 'EUR';

  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(value: number | string | null | undefined, compactNotation?: boolean): string | null {
    return this.currencyPipe.transform(value, this.CURRENCY_FORMAT, 'symbol', compactNotation ? '1.0-2' : null);
  }
}
