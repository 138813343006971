import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { TvhTransactionStatus } from '../../admin/constants/transaction-status';
import { LocationState } from '../../location/store/location.state';
import { NavigateService } from '../../shared/services/navigate.service';
import { BasePaginatedTableState, BasePaginatedTableStateModel } from '../../shared/store/base-paginated-table.state';
import { TvhUserTransactionLog } from '../models/user-transaction-log';
import { UserTransactionsApiService } from '../services/user-transactions-api.service';

export interface TransactionsStateModel extends BasePaginatedTableStateModel<TvhUserTransactionLog> {
  filterForm: {
    model: {
      action: TvhTransactionStatus | '';
      dateFrom: string;
      dateTo: string;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
}

@StateRepository()
@State<TransactionsStateModel>({
  name: 'userTransactions',
  defaults: {
    pageItems: [],
    paginatorIndex: 0,
    pageSize: 10,
    totalCount: 0,
    filterForm: {
      model: {
        action: '',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
      },
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable()
export class UserTransactionsState extends BasePaginatedTableState<TvhUserTransactionLog> {
  searchOnReset = true;

  constructor(
    protected api: UserTransactionsApiService,
    protected readonly navigate: NavigateService,
    private readonly locationState: LocationState,
  ) {
    super(api, navigate);
  }

  @DataAction() cancel(): void {
    if (this.locationState.isLocationConfirmed) {
      this.navigate.toLocationById(this.locationState.location.id);
    } else {
      this.navigate.toLocationSelect();
    }
  }
}
