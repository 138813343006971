import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';

import { NavigateService } from '../services/navigate.service';
import { AuthState } from '../store/auth.state';

export const hasManagerRole: CanMatchFn = () => {
  if (inject(AuthState).isManager) {
    return true;
  }

  inject(NavigateService).toLocationSelect();

  return false;
};
