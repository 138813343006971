import { Component } from '@angular/core';
import { PaymentLandingState } from '../../store/payment-landing.state';

@Component({
  selector: 'tvh-payment-landing',
  templateUrl: './payment-landing.component.html',
  styleUrls: ['./payment-landing.component.scss'],
})
export class PaymentLandingComponent {
  constructor(readonly paymentLanding: PaymentLandingState) {}
}
