import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { PaymentLandingState } from '../store/payment-landing.state';

export const canActivateAfterPayment: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const idParam = route.paramMap.get('paymentId');

  if (idParam) {
    const id = parseInt(idParam, 10);
    inject(PaymentLandingState).setPaymentId(id);

    return true;
  }

  return false;
};
