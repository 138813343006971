import { Directive, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class SubSinkComponent implements OnDestroy {
  protected subs = new SubSink();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
