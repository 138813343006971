import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ApiUtilsService } from '../services/api-utils.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  static readonly DEFAULT_REQ_TIMEOUT: number = 60000;

  constructor(
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    // skip handler if requested
    if (req.headers.has(ApiUtilsService.SKIP_ERROR_INTERCEPTOR_HEADER)) {
      const headers = req.headers.delete(ApiUtilsService.SKIP_ERROR_INTERCEPTOR_HEADER);

      return next.handle(req.clone({ headers }));
    }

    // handle errors otherwise
    return next.handle(req).pipe(
      timeout(ErrorInterceptor.DEFAULT_REQ_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        if (error) {
          // Internal server error
          // and other server-side errors
          if (error.status >= 500) {
            this.toastr.error(this.translate.instant('ERRORS.UNKNOWN'), this.translate.instant('ERRORS.TITLE.UNKNOWN'));
          }

          // Not found
          if (error.status === 404) {
            this.toastr.error(
              this.translate.instant('ERRORS.NOT_FOUND'),
              this.translate.instant('ERRORS.TITLE.NOT_FOUND'),
            );
          }

          // Forbidden
          if (error.status === 403) {
            this.toastr.error(
              this.translate.instant('ERRORS.FORBIDDEN'),
              this.translate.instant('ERRORS.TITLE.FORBIDDEN'),
            );
          }

          // Unauthorized
          if (error.status === 401) {
            this.toastr.error(
              this.translate.instant('ERRORS.UNAUTHORIZED'),
              this.translate.instant('ERRORS.TITLE.UNAUTHORIZED'),
            );
          }
        }

        return throwError(error);
      }),
    );
  }
}
