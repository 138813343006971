<div class="content">
  <h2>{{ 'CONTACT.CONTACT_US_TITLE' | translate }}</h2>
  <form [formGroup]="form" ngxsForm="contact.contactForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'CONTACT.QUESTION' | translate }}</mat-label>
      <textarea matInput formControlName="question" required rows="15"></textarea>
      <mat-error *ngIf="form.controls.question.invalid">{{ 'CONTACT.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </form>

  <button class="submit-button" mat-raised-button color="primary" (click)="send()" [disabled]="!form.valid">
    {{ 'CONTACT.SEND' | translate }}
  </button>

  <button class="cancel-button" mat-raised-button (click)="cancel()">
    <span>{{ 'COMMON.CANCEL' | translate }}</span>
  </button>

  <div class="company-info">
    <h3>{{ 'CONTACT.COMPANY_DATA' | translate }}</h3>
    <div>Tom Van Horebeek BVBA</div>
    <div>Zwetstraat 5</div>
    <div>2520 Ranst</div>
    <div>KBO BE0896741640</div>
  </div>
</div>
