import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileState } from '../../profile/store/profile.state';
import { ApiUtilsService } from '../../shared/services/api-utils.service';
import { AppConfigState } from '../../shared/store/app-config.state';
import { TvhPayment } from '../models/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private readonly config: AppConfigState,
    private readonly http: HttpClient,
    private readonly profile: ProfileState,
  ) {}

  addPayment(amount: number): void {
    const payment: TvhPayment = {
      amount,
      userId: this.profile.currentUserId,
    };

    this.http.post(`${this.config.apiPath}/payments`, payment).subscribe((res: { transactionCheckoutUrl: string }) => {
      window.location.href = res.transactionCheckoutUrl;
    });
  }

  getPayment(paymentId: number, noLoadingIndicator = false): Observable<TvhPayment> {
    return this.http.get<TvhPayment>(`${this.config.apiPath}/payments/${paymentId}`, {
      headers: ApiUtilsService.skipErrorInterceptor(
        noLoadingIndicator ? ApiUtilsService.skipLoadingInterceptor() : undefined,
      ),
    });
  }
}
