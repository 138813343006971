<tvh-language-select *ngIf="!langState.lang" (langSelected)="setAppLanguage($event)"></tvh-language-select>

<!--Amplify Auth-->
<amplify-authenticator *ngIf="langState.lang" hideSignUp>
  <ng-template amplifySlot="sign-in">
    <div class="sign-in-container">
      <div class="sign-in">
        <img class="logo" src="assets/images/qreddi_logo_pos_rgb.svg" />
        <mat-progress-bar class="loading-bar" mode="indeterminate" *ngIf="auth.isInProgress"></mat-progress-bar>
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ 'SIGN_IN.SIGN_IN' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="social-login-buttons">
              <cool-facebook-button color="dark" (click)="auth.signInFacebook()" [disabled]="auth.isInProgress">
                {{ 'SIGN_IN.WITH_FACEBOOK' | translate }}
              </cool-facebook-button>
              <cool-google-button color="dark" (click)="auth.signInGoogle()" [disabled]="auth.isInProgress">
                {{ 'SIGN_IN.WITH_GOOGLE' | translate }}
              </cool-google-button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-template>

  <ng-template amplifySlot="authenticated">
    <div class="toolbar" role="banner">
      <mat-toolbar color="primary">
        <button mat-icon-button class="home" aria-label="Home" (click)="toHome()">
          <mat-icon>home</mat-icon>
        </button>
        <div class="page-title">{{ titleState.title }}</div>
        <div class="spacer"></div>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu" aria-label="Menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="auth.isManager" mat-menu-item (click)="toSettings()">
            <mat-icon>settings</mat-icon>
            <span>{{ 'ADMIN.TITLE' | translate }}</span>
          </button>
          <button mat-menu-item (click)="toProfile()">
            <mat-icon>account_circle</mat-icon>
            <span>{{ 'MENU.PROFILE' | translate }}</span>
          </button>
          <button mat-menu-item (click)="toTransactionHistory()">
            <mat-icon>history</mat-icon>
            <span>{{ 'MENU.TRANSACTIONS' | translate }}</span>
          </button>
          <button mat-menu-item (click)="toAddCredit()">
            <mat-icon>credit_card_outline</mat-icon>
            <span>{{ 'MENU.ADD_CREDIT' | translate }}</span>
          </button>
          <button mat-menu-item (click)="toLocation()">
            <mat-icon>place</mat-icon>
            <span>{{ 'MENU.CHANGE_LOCATION' | translate }}</span>
          </button>
          <button mat-menu-item (click)="changeLanguage()">
            <mat-icon>translate</mat-icon>
            <span>{{ 'MENU.LANGUAGE' | translate }}</span>
          </button>
          <button mat-menu-item (click)="toContactForm()">
            <mat-icon>help_center</mat-icon>
            <span>{{ 'MENU.CONTACT' | translate }}</span>
          </button>
          <button mat-menu-item (click)="auth.signOut()">
            <mat-icon>logout</mat-icon>
            <span>{{ 'MENU.SIGN_OUT' | translate }}</span>
          </button>
        </mat-menu>
      </mat-toolbar>
    </div>
    <div class="balance-bar" role="complementary">
      <mat-toolbar color="accent">
        <span *ngIf="locationState.locationName">{{ locationState.locationName }}</span>
        <div class="spacer"></div>
        <span *ngIf="profile.balance >= 0">{{ 'MENU.BALANCE' | translate }}: {{ profile.balance | euro }}</span>
      </mat-toolbar>
    </div>

    <mat-progress-bar class="loading-bar" mode="indeterminate" *ngIf="loading.showBar"></mat-progress-bar>
    <div class="application-content">
      <div class="loading-spinner" *ngIf="loading.showSpinner">
        <mat-spinner color="accent"></mat-spinner>
      </div>

      <router-outlet></router-outlet>
    </div>
  </ng-template>
</amplify-authenticator>
