import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { TvhMachineStatus } from '../constants/machine-status.constant';
import { TvhMachineType } from '../constants/machine-type.constant';
import { Machine } from '../models/machine.model';
import { MachineUtilsService } from '../services/machine-utils.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseMachineCardComponent {
  @Input() machine!: Machine;
  @Input() showMachineStatus = false;
  @Input() failedState = false;

  @Output() readonly machineClick: EventEmitter<Machine> = new EventEmitter<Machine>();

  clicked = () => {
    this.machineClick.emit(this.machine);
  };

  protected get isBusy(): boolean {
    return this.showMachineStatus && MachineUtilsService.isBusy(this.machine);
  }

  protected get isInactive(): boolean {
    return this.showMachineStatus && MachineUtilsService.isInactive(this.machine);
  }
}
