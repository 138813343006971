import { Component } from '@angular/core';
import { LocationState } from '../../../location/store/location.state';
import { ProfileState } from '../../../profile/store/profile.state';

@Component({
  selector: 'tvh-payment-landing',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent {
  constructor(
    readonly profile: ProfileState,
    private readonly location: LocationState,
  ) {}

  continue = () => {
    this.location.toHome();
  };
}
