import { inject, Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { TvhMachineType } from '../../location/modules/machine/constants/machine-type.constant';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  private store: Store = inject(Store);

  toAddCredit = () => {
    this.store.dispatch(new Navigate(['add-credit']));
  };

  toContactForm = () => {
    this.store.dispatch(new Navigate(['contact']));
  };

  toLocationSelect = () => {
    this.store.dispatch(new Navigate(['location']));
  };

  toLocationById = (locationId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId]));
  };

  toLocationMachines = (locationId: number | string, type: TvhMachineType) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine'], { mt: type }));
  };

  toMachineAddTime = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'add-time']));
  };

  toMachineDetail = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId]));
  };

  toMachineInUse = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'in-use']));
  };

  toMachineProgramSelect = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'program']));
  };

  toMachineStartFailure = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'failed']));
  };

  toMachineStartInsufficientFunds = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'insufficient']));
  };

  toMachineStartSuccess = (locationId: number | string, machineId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'machine', machineId, 'success']));
  };

  toProfile = () => {
    this.store.dispatch(new Navigate(['profile']));
  };

  toUserTransactions = () => {
    this.store.dispatch(new Navigate(['transactions']));
  };

  toManagement = () => {
    this.store.dispatch(new Navigate(['mgmt']));
  };

  toAdminFinancialHistory = () => {
    this.store.dispatch(new Navigate(['mgmt', 'admin', 'financial']));
  };

  toMgmtMachineControl = () => {
    this.store.dispatch(new Navigate(['mgmt', 'machine-control']));
  };

  toAdminPaymentConfig = () => {
    this.store.dispatch(new Navigate(['mgmt', 'admin', 'config']));
  };

  toAdminRevenue = () => {
    this.store.dispatch(new Navigate(['mgmt', 'admin', 'revenue']));
  };

  toAdminTransactions = () => {
    this.store.dispatch(new Navigate(['mgmt', 'admin', 'transactions']));
  };

  toStartActivity = (locationId: number | string) => {
    this.store.dispatch(new Navigate(['location', locationId, 'start']));
  };

  toPaymentError = (paymentId: number | string) => {
    this.store.dispatch(new Navigate(['afterpayment', paymentId, 'error']));
  };

  toPaymentSuccess = (paymentId: number | string) => {
    this.store.dispatch(new Navigate(['afterpayment', paymentId, 'success']));
  };
}
