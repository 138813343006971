import { Pipe, PipeTransform } from '@angular/core';
import { TvhMachineType } from '../../location/modules/machine/constants/machine-type.constant';
import { Machine } from '../../location/modules/machine/models/machine.model';

@Pipe({
  name: 'machineType',
})
export class MachineTypePipe implements PipeTransform {
  transform(value: Array<Machine>, filter: TvhMachineType): Array<Machine> {
    if (!value) {
      return [];
    }

    if (!!filter) {
      return value.filter((m: Machine) => m.type === filter);
    }

    return value;
  }
}
