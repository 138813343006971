import { Computed, DataAction, Payload, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { State, Store } from '@ngxs/store';
import { Immutable } from 'immer';
import { ToastrService } from 'ngx-toastr';
import { NavigateService } from '../../shared/services/navigate.service';
import { TvhPaymentConfig } from '../models/payment-config';
import { PaymentConfigurationApiService } from '../services/payment-configuration-api.service';

export interface PaymentConfigurationStateModel {
  configForm: {
    model: {
      minimalPaymentAmount: number;
      benefitBaseAmount: number;
      benefitAmount: number;
      financialYearStartDay: number;
      financialYearStartMonth: number;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
  exampleMultiples: Array<number>;
}

@StateRepository()
@State<PaymentConfigurationStateModel>({
  name: 'paymentConfig',
  defaults: {
    configForm: {
      model: {
        minimalPaymentAmount: 0,
        benefitBaseAmount: 0,
        benefitAmount: 0,
        financialYearStartDay: 1,
        financialYearStartMonth: 1,
      },
      dirty: false,
      status: '',
      errors: {},
    },
    exampleMultiples: [1, 2, 3, 4, 5],
  },
})
@Injectable()
export class PaymentConfigurationState extends NgxsImmutableDataRepository<PaymentConfigurationStateModel> {
  @Computed() get bonusSystemActive(): boolean {
    return !!this.snapshot.configForm.model.benefitBaseAmount && !!this.snapshot.configForm.model.benefitAmount;
  }

  @Computed() get bonusBaseAmount(): number {
    return this.snapshot.configForm.model.benefitBaseAmount;
  }

  @Computed() get bonusAmount(): number {
    return this.snapshot.configForm.model.benefitAmount;
  }

  @Computed() get minimumAmount(): number {
    return this.snapshot.configForm.model.minimalPaymentAmount;
  }

  @Computed() get exampleMultiples(): Immutable<Array<number>> {
    return this.snapshot.exampleMultiples;
  }

  constructor(
    private readonly navigate: NavigateService,
    private readonly paymentConfig: PaymentConfigurationApiService,
    private readonly store: Store,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    // retrieve current settings
    this.getDataFromBackend();
  }

  @DataAction() updateConfiguration(@Payload('config') config: TvhPaymentConfig): void {
    this.paymentConfig.updatePaymentConfig(config).subscribe(
      (updated) => {
        this.updateForm(updated);
        this.toastr.success(this.translate.instant('ADMIN.CONFIGURATION.MSG.SUCCESS'));
        this.navigate.toManagement();
      },
      () => {
        this.toastr.error(this.translate.instant('ADMIN.CONFIGURATION.MSG.FAILURE'));
      },
    );
  }

  @DataAction() cancel(): void {
    this.navigate.toManagement();
    this.getDataFromBackend();
  }

  private getDataFromBackend(): void {
    this.paymentConfig.getPaymentConfig().subscribe((res) => {
      this.updateForm(res);
    });
  }

  private updateForm(config: TvhPaymentConfig): void {
    this.store.dispatch(
      new UpdateFormValue({
        value: {
          minimalPaymentAmount: config.minimalPaymentAmount ?? 0,
          benefitBaseAmount: config.benefitBaseAmount ?? 0,
          benefitAmount: config.benefitAmount ?? 0,
          financialYearStartDay: config.financialYearStartDay ?? 1,
          financialYearStartMonth: config.financialYearStartMonth ?? 1,
        },
        path: 'paymentConfig.configForm',
      }),
    );
  }
}
