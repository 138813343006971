import { Component, Input } from '@angular/core';
import { TvhMachineType } from '../../../location/modules/machine/constants/machine-type.constant';
import { Machine } from '../../../location/modules/machine/models/machine.model';
import { MachineUtilsService } from '../../../location/modules/machine/services/machine-utils.service';

@Component({
  selector: 'tvh-machine-icons',
  templateUrl: './machine-icons.component.html',
  styleUrls: ['./machine-icons.component.scss'],
})
export class MachineIconsComponent {
  @Input() machine!: Machine;
  @Input() showMachineStatus = false;
  @Input() failedState = false;

  private _icon: string;

  get icon(): string {
    if (!this._icon) {
      switch (this.machine.type) {
        case TvhMachineType.BREAD_MACHINE:
          this._icon = 'bread-machine';
          break;
        case TvhMachineType.CAR_WASH:
          this._icon = 'car-wash';
          break;
        case TvhMachineType.COFFEE_DISPENSER:
          this._icon = 'coffee';
          break;
        case TvhMachineType.DOOR:
          this._icon = 'machine-door';
          break;
        case TvhMachineType.DRINK_MACHINE:
          this._icon = 'drink-machine';
          break;
        case TvhMachineType.DRYER:
          this._icon = 'machine-dryer';
          break;
        case TvhMachineType.IRONING_ROLL:
          this._icon = 'machine-ironing-roll';
          break;
        case TvhMachineType.LIGHT:
          this._icon = 'machine-light';
          break;
        case TvhMachineType.TICKET_MACHINE:
          this._icon = 'local-activity';
          break;
        case TvhMachineType.TOKEN_DISPENSER:
          this._icon = 'shopping-basket';
          break;
        case TvhMachineType.VACUUM_CLEANER:
          this._icon = 'cleaning-services';
          break;
        case TvhMachineType.VENDING_MACHINE:
          this._icon = 'shopping-bar';
          break;
        case TvhMachineType.WASHING_MACHINE:
          this._icon = 'machine-washer';
          break;
        default:
          this._icon = undefined;
      }
    }

    return this._icon;
  }

  get machineColor(): string {
    return this.isBusy || this.failedState
      ? 'warn'
      : this.isAvailable
        ? 'success'
        : this.isInactive
          ? 'inactive'
          : 'primary';
  }

  protected get isAvailable(): boolean {
    return this.showMachineStatus && MachineUtilsService.isAvailable(this.machine);
  }

  protected get isBusy(): boolean {
    return this.showMachineStatus && MachineUtilsService.isBusy(this.machine);
  }

  protected get isInactive(): boolean {
    return this.showMachineStatus && MachineUtilsService.isInactive(this.machine);
  }
}
