import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileComponent } from '../profile.component';
import { ProfileState } from '../store/profile.state';

export const canDeactivateProfile: CanDeactivateFn<ProfileComponent> = () => {
  // only allow the user to leave the ProfileComponent if the profile is filled in
  const profileOk = !!inject(ProfileState).currentUserId;
  if (!profileOk) {
    inject(ToastrService).warning(inject(TranslateService).instant('PROFILE.MSG.PROFILE_COMPLETION_REQUIRED'));
  }

  return profileOk;
};
