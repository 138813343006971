<div class="content">
  <h1>{{ 'LANDING.THANK_YOU' | translate }}</h1>
  <h2>{{ 'LANDING.PAYMENT_RECEIVED' | translate }}</h2>
  <h3>{{ 'LANDING.NEW_BALANCE_AMOUNT' | translate }}</h3>

  <div class="new-balance">
    {{ profile.balance | euro }}
  </div>

  <button class="other-amount" mat-raised-button color="primary" (click)="continue()">
    <span>{{ 'COMMON.CONTINUE' | translate }}</span>
  </button>
</div>
