<div>
  <mat-card class="filters mat-elevation-z3">
    <mat-card-content>
      <form class="filters" [formGroup]="form" ngxsForm="userTransactions.filterForm" [ngxsFormDebounce]="-1">
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'TRANSACTIONS.FROM' | translate }}</mat-label>
            <input matInput formControlName="dateFrom" [matDatepicker]="fromPicker" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'TRANSACTIONS.TO' | translate }}</mat-label>
            <input matInput formControlName="dateTo" [matDatepicker]="toPicker" />
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'ADMIN.TRANSACTIONS.STATUS' | translate }}</mat-label>
            <mat-select formControlName="action">
              <mat-option [value]="''"></mat-option>
              <ng-container *ngFor="let status of statuses; trackBy: utils.trackByIndex">
                <mat-option [value]="status">{{ 'ADMIN.TRANSACTIONS.STATUS_' + status | translate }} </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
      <button mat-button color="primary" (click)="state.search()">
        {{ 'ADMIN.TRANSACTIONS.BUTTONS.SEARCH' | translate | uppercase }}
      </button>
      <button mat-button (click)="state.resetFilters()">
        {{ 'ADMIN.TRANSACTIONS.BUTTONS.RESET' | translate | uppercase }}
      </button>
    </mat-card-actions>
  </mat-card>

  <div class="transaction-cards">
    <tvh-user-transaction-card
      *ngFor="let transaction of state.pageItems; trackBy: utils.trackByIndex"
      [transaction]="transaction"
    ></tvh-user-transaction-card>

    <mat-card *ngIf="!state.pageItems.length" class="mat-elevation-z3">
      <mat-card-content>
        {{ 'COMMON.TABLE.NO_DATA' | translate }}
      </mat-card-content>
    </mat-card>
  </div>

  <mat-paginator
    class="paginator mat-elevation-z3"
    [length]="state.totalCount"
    [pageSizeOptions]="[5, 10, 20]"
    [pageIndex]="state.paginatorIndex"
    [pageSize]="state.pageSize"
    (page)="state.changePage($event)"
  >
  </mat-paginator>
</div>

<div class="content">
  <button class="cancel-button" mat-raised-button color="primary" (click)="state.cancel()">
    <span>{{ 'COMMON.DONE' | translate }}</span>
  </button>
</div>
