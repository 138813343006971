import { Injectable } from '@angular/core';
import { TvhMachineStatus } from '../constants/machine-status.constant';
import { TvhMachineType } from '../constants/machine-type.constant';
import { Machine } from '../models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class MachineUtilsService {
  static canAddTime(machine: Machine): boolean {
    // Time can only be added for Dryers and Ironing Rolls
    return MachineUtilsService.isDryer(machine) || MachineUtilsService.isIroningRoll(machine);
  }

  static isActive(machine: Machine): boolean {
    return MachineUtilsService.isAvailable(machine) || MachineUtilsService.isBusy(machine);
  }

  // STATUS CHECKS
  static isAvailable(machine: Machine): boolean {
    return machine.status === TvhMachineStatus.AVAILABLE;
  }

  static isBusy(machine: Machine): boolean {
    return machine.status === TvhMachineStatus.BUSY;
  }

  static isInactive(machine: Machine): boolean {
    return machine.status === TvhMachineStatus.DEACTIVATED;
  }

  static isOurs(machine: Machine, currentUserId: number): boolean {
    return machine.customerId === currentUserId;
  }

  // TYPE CHECKS
  static isBreadMachine(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.BREAD_MACHINE);
  }

  static isCarWash(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.CAR_WASH);
  }

  static isCoffeeDispenser(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.COFFEE_DISPENSER);
  }

  static isDoor(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.DOOR);
  }

  static isDrinkMachine(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.DRINK_MACHINE);
  }

  static isDryer(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.DRYER);
  }

  static isIroningRoll(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.IRONING_ROLL);
  }

  static isLight(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.LIGHT);
  }

  static isTicketMachine(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.TICKET_MACHINE);
  }

  static isTokenDispenser(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.TOKEN_DISPENSER);
  }

  static isVacuumCleaner(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.VACUUM_CLEANER);
  }

  static isVendingMachine(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.VENDING_MACHINE);
  }

  static isWashingMachine(machine: Machine): boolean {
    return MachineUtilsService.checkType(machine, TvhMachineType.WASHING_MACHINE);
  }

  // PRIVATE METHODS
  private static checkType(machine: Machine, type: TvhMachineType): boolean {
    return machine.type === type;
  }
}
