import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TvhTransactionStatus } from '../admin/constants/transaction-status';
import { ProfileState } from '../profile/store/profile.state';
import { UtilService } from '../shared/services/util.service';
import { UserTransactionsState } from './store/user-transactions.state';

@Component({
  selector: 'tvh-user-transactions',
  templateUrl: './user-transactions.component.html',
  styleUrls: ['./user-transactions.component.scss'],
})
export class UserTransactionsComponent implements OnInit, OnDestroy {
  displayedColumns: Array<string> = [
    'timestamp',
    'name',
    'lastName',
    'amount',
    'status',
    'email',
    'phone',
    'laundromat',
    'machine',
  ];
  form = new UntypedFormGroup({
    action: new UntypedFormControl(),
    dateFrom: new UntypedFormControl(),
    dateTo: new UntypedFormControl(),
  });
  statuses = [
    TvhTransactionStatus.MACHINE_STARTED,
    TvhTransactionStatus.MACHINE_RESERVED,
    TvhTransactionStatus.MACHINE_CANCELLED,
    TvhTransactionStatus.MONEY_DEPOSIT,
    TvhTransactionStatus.MONEY_DEPOSIT_BONUS,
  ];
  utils = UtilService;

  private initialized = false;

  constructor(
    readonly state: UserTransactionsState,
    readonly profile: ProfileState,
  ) {}

  ngOnInit(): void {
    this.profile.state$.subscribe((s) => {
      if (s.current?.id && !this.initialized) {
        this.state.getDataFromBackend();
        this.initialized = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.state.reset();
  }
}
