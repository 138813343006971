import { Component, Input } from '@angular/core';
import { ProfileState } from '../../../../../profile/store/profile.state';
import { MachineUtilsService } from '../../services/machine-utils.service';
import { BaseMachineCardComponent } from '../base-machine-card.component';

@Component({
  selector: 'tvh-machine-card',
  templateUrl: './machine-card.component.html',
  styleUrls: ['./machine-card.component.scss'],
})
export class MachineCardComponent extends BaseMachineCardComponent {
  @Input() disableUnavailable = false;

  constructor(private readonly profile: ProfileState) {
    super();
  }

  get isDisabled(): boolean {
    return (
      this.disableUnavailable &&
      ((this.isBusy && !MachineUtilsService.isOurs(this.machine, this.profile.currentUserId)) || this.isInactive)
    );
  }
}
