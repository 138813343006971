<div class="content">
  <h3>{{ 'ADD_CREDIT.CHOOSE_AMOUNT' | translate }}</h3>

  <ng-container *ngFor="let bonus of bonusAmounts; trackBy: utils.trackByIndex">
    <button class="bonus-amount" mat-raised-button color="primary" (click)="addBonus(bonus)">
      <span>
        {{ paymentConfig.bonusBaseAmount * bonus | euro: true }}
        <span *ngIf="paymentConfig.bonusAmount">
          + {{ paymentConfig.bonusAmount * bonus | euro: true }} {{ 'ADD_CREDIT.GRATIS' | translate }}</span
        >
      </span>
    </button>
  </ng-container>

  <button class="other-amount" mat-raised-button color="primary" (click)="addOther()">
    <span>{{ 'ADD_CREDIT.OTHER_AMOUNT' | translate }}</span>
  </button>
  <h5>{{ 'ADD_CREDIT.MINIMUM_AMOUNT' | translate }}: {{ paymentConfig.minimumAmount | euro: true }}</h5>

  <button class="cancel-button" mat-raised-button (click)="cancel()">
    <span>{{ 'COMMON.CANCEL' | translate }}</span>
  </button>
</div>
