<div class="content">
  <h1 class="mat-warn">{{ 'LANDING.UH_OH' | translate }}</h1>
  <h2 class="mat-warn">{{ 'LANDING.PAYMENT_NOT_COMPLETED' | translate }}</h2>
  <h3>{{ 'LANDING.BALANCE_NOT_MODIFIED' | translate }}</h3>

  <div class="new-balance">
    {{ profile.balance | euro }}
  </div>

  <button class="other-amount" mat-raised-button color="primary" (click)="continue()">
    <span>{{ 'COMMON.RETRY' | translate }}</span>
  </button>
  <button class="other-amount" mat-raised-button (click)="cancel()">
    <span>{{ 'COMMON.CANCEL' | translate }}</span>
  </button>
</div>
