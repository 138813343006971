import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { State } from '@ngxs/store';
import { produce } from 'immer';
import { filter } from 'rxjs/operators';

export interface LoadingStateModel {
  showLoadingBar: boolean;
  showSpinner: boolean;
}

@StateRepository()
@State<LoadingStateModel>({
  name: 'loading',
  defaults: {
    showLoadingBar: false,
    showSpinner: true,
  },
})
@Injectable()
export class LoadingState extends NgxsImmutableDataRepository<LoadingStateModel> {
  @Computed() get showBar(): boolean {
    return this.snapshot.showLoadingBar;
  }

  @Computed() get showSpinner(): boolean {
    return this.snapshot.showSpinner;
  }

  @Computed() get isLoading(): boolean {
    return this.showBar || this.showSpinner;
  }

  constructor(private readonly router: Router) {
    super();

    // Show spinner when navigation starts
    router.events.pipe(filter((e): e is NavigationStart => e instanceof NavigationStart)).subscribe(() => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.showSpinner = true;
        }),
      );
    });

    // Hide spinner when navigation is canceled (usually because of a guard)
    router.events.pipe(filter((e): e is NavigationCancel => e instanceof NavigationCancel)).subscribe(() => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.showSpinner = false;
        }),
      );
    });

    // Hide spinner when navigation ends
    router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(() => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.showSpinner = false;
        }),
      );
    });
  }

  @DataAction() apiRequestStarted(): void {
    setTimeout(() => {
      this.ctx.setState(
        produce(this.ctx.getState(), (draft) => {
          draft.showLoadingBar = true;
        }),
      );
    });
  }

  @DataAction() apiRequestComplete(): void {
    setTimeout(() => {
      this.ctx.setState(
        produce(this.ctx.getState(), (draft) => {
          draft.showLoadingBar = false;
        }),
      );
    });
  }
}
