import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { LocationState } from '../location/store/location.state';
import { ContactState } from './store/contact.state';

@Component({
  selector: 'tvh-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnDestroy {
  form = new UntypedFormGroup({
    question: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private readonly location: LocationState,
    private readonly contact: ContactState,
  ) {}

  ngOnDestroy(): void {
    this.contact.reset();
  }

  send = (): void => {
    const mail = this.compose();
    const a = document.createElement('a');
    a.href = mail;
    a.click();
  };

  cancel = (): void => {
    this.location.toHome();
  };

  private readonly compose = (): string => {
    let link = 'mailto:';
    const receiver = 'info.qreddi@gmail.com';
    const subject = 'Qreddi Contact Form';

    const properties = [];

    link += receiver;
    properties.push(`subject=${encodeURIComponent(subject)}`);
    properties.push(`body=${encodeURIComponent(this.form.controls.question.value)}`);
    link += `?${properties.join('&')}`;

    return link;
  };
}
