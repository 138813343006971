import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { PaymentService } from '../services/payment.service';

export interface CustomAmountPaymentStateModel {
  customAmountForm: {
    model: {
      amount: number;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
}

@StateRepository()
@State<CustomAmountPaymentStateModel>({
  name: 'customAmount',
  defaults: {
    customAmountForm: {
      model: {
        amount: 0,
      },
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable()
export class CustomAmountPaymentState extends NgxsImmutableDataRepository<CustomAmountPaymentStateModel> {
  constructor(private readonly paymentSvc: PaymentService) {
    super();
  }

  @DataAction() startCustomPayment(): void {
    this.paymentSvc.addPayment(this.snapshot.customAmountForm.model.amount);
  }
}
