<div class="content">
  <button class="lang-button" mat-raised-button color="primary" (click)="selectLang(lang.NL)">
    <span>Ga verder in het Nederlands</span>
  </button>

  <button class="lang-button" mat-raised-button color="primary" (click)="selectLang(lang.FR)">
    <span>Continuer en français</span>
  </button>

  <button class="lang-button" mat-raised-button color="primary" (click)="selectLang(lang.EN)">
    <span>Continue in English</span>
  </button>
</div>
