import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApiUtilsService } from '../services/api-utils.service';
import { LoadingState } from '../store/loading.state';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly loadingState: LoadingState) {}

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    if (req.headers.has(ApiUtilsService.SKIP_LOADING_INTERCEPTOR_HEADER)) {
      const headers = req.headers.delete(ApiUtilsService.SKIP_LOADING_INTERCEPTOR_HEADER);

      return next.handle(req.clone({ headers }));
    }

    this.loadingState.apiRequestStarted();

    return next.handle(req).pipe(finalize(() => this.loadingState.apiRequestComplete()));
  }
}
