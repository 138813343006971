import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  constructor(private readonly decimalPipe: DecimalPipe) {}

  transform(value: number | null | undefined): string | null {
    if (!value) {
      return null;
    }

    if (value > 1000) {
      return `~${this.decimalPipe.transform(value / 1000, '1.0-2')} km`;
    }

    return `~${this.decimalPipe.transform(value, '1.0-0')} m`;
  }
}
