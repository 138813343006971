import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ProfileState } from '../../profile/store/profile.state';
import { Page } from '../../shared/models/page';
import { BasePaginatedApiService } from '../../shared/services/base-paginated-api.service';
import { AppConfigState } from '../../shared/store/app-config.state';
import { TvhUserTransactionLog } from '../models/user-transaction-log';

@Injectable({
  providedIn: 'root',
})
export class UserTransactionsApiService extends BasePaginatedApiService<TvhUserTransactionLog> {
  constructor(
    private readonly config: AppConfigState,
    private readonly http: HttpClient,
    private readonly profile: ProfileState,
  ) {
    super();
  }

  doApiCall(httpParams: HttpParams): Observable<Page<TvhUserTransactionLog>> {
    return this.http.get<Page<TvhUserTransactionLog>>(
      `${this.config.apiPath}/transactions/user/${this.profile.currentUserId}`,
      {
        params: httpParams,
      },
    );
  }

  preProcessFilters(filters: { [p: string]: string } | undefined): { [p: string]: string } {
    const paramFilters = { ...filters };
    if (paramFilters && paramFilters.dateFrom && paramFilters.dateTo) {
      paramFilters.dateFrom = DateTime.fromJSDate(new Date(paramFilters.dateFrom)).toFormat('yyyy-MM-dd');
      paramFilters.dateTo = DateTime.fromJSDate(new Date(paramFilters.dateTo)).toFormat('yyyy-MM-dd');
    }

    return paramFilters;
  }
}
