import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { produce } from 'immer';

export interface AppConfigStateModel {
  api: string;
  refreshInterval: number;
  ready: boolean;
  signalR: string;
}

@StateRepository()
@State<AppConfigStateModel>({
  name: 'config',
  defaults: {
    api: '/api/v1',
    refreshInterval: 30000, // 30 seconds
    ready: false,
    signalR: '/api/notificationhub',
  },
})
@Injectable()
export class AppConfigState extends NgxsImmutableDataRepository<AppConfigStateModel> {
  @Computed() get apiPath(): string {
    return this.snapshot.api;
  }

  @Computed() get refreshInterval(): number {
    return this.snapshot.refreshInterval;
  }

  @Computed() get signalRPath(): string {
    return this.snapshot.signalR;
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    this.markReady();
  }

  @DataAction() markReady(): void {
    this.ctx.setState(
      produce(this.getState(), (draft) => {
        draft.ready = true;
      }),
    );
  }
}
