import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsImmutableDataRepository } from '@angular-ru/ngxs/repositories';
import { EventEmitter, inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@ngxs/store';
import { AuthSession, fetchAuthSession, JWT, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Immutable, produce } from 'immer';
import { interval, Observable, Subscription } from 'rxjs';
import { LocationState } from '../../location/store/location.state';
import { AppConfigState } from './app-config.state';

export interface AuthStateModel {
  signedIn: boolean;
  inProgress: boolean;
  accessToken: JWT;
  idToken: JWT;
}

const POST_SIGN_IN_PATH = 'tvh_post_sign_in_path';

@StateRepository()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    signedIn: false,
    inProgress: false,
    accessToken: undefined,
    idToken: undefined,
  },
})
@Injectable()
export class AuthState extends NgxsImmutableDataRepository<AuthStateModel> {
  refreshTrigger$: Observable<void>;

  private readonly refreshEmitter: EventEmitter<void> = new EventEmitter<void>();
  private refreshInterval: Subscription;

  #config: AppConfigState = inject(AppConfigState);
  #location: LocationState = inject(LocationState);
  #router: Router = inject(Router);
  #zone: NgZone = inject(NgZone);

  constructor() {
    super();
    this.refreshTrigger$ = this.refreshEmitter.asObservable();
  }

  ngxsOnInit(): void {
    super.ngxsOnInit();

    Hub.listen('auth', (data) => {
      const event = data.payload.event;
      if (event === 'signedOut') {
        console.warn('user signed out...');
        this.stopPeriodicRefresh();
      }
      if (event === 'signedIn') {
        this.#zone.run(() => {
          void this.#router.navigateByUrl(localStorage.getItem(POST_SIGN_IN_PATH) ?? 'location');
        });
        localStorage.removeItem(POST_SIGN_IN_PATH);
      }
      if (event === 'signInWithRedirect') {
        localStorage.setItem('amplify-redirected-from-hosted-ui', 'false');
      }
    });
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    this.#location.loadLocations();
    this.checkActiveSession();
  }

  @Computed() get idToken(): Immutable<JWT> {
    return this.snapshot.idToken;
  }

  @Computed() get userFirstName(): string {
    return this.idToken.payload.given_name as string;
  }

  @Computed() get userLastName(): string {
    return this.idToken.payload.family_name as string;
  }

  @Computed() get userEmailAddress(): string {
    return this.idToken.payload.email as string;
  }

  @Computed() get signedIn(): boolean {
    return this.snapshot.signedIn;
  }

  @Computed() get isAdmin(): boolean {
    return (this.snapshot.accessToken?.payload['cognito:groups'] as Array<string>)?.includes('admins');
  }

  @Computed() get isInProgress(): boolean {
    return this.snapshot.inProgress;
  }

  @Computed() get isManager(): boolean {
    return (
      (this.snapshot.accessToken?.payload['cognito:groups'] as Array<string>)?.includes('managers') || this.isAdmin
    );
  }

  @DataAction() signInFacebook(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.inProgress = true;
      }),
    );
    this.signIn('Facebook');
  }

  @DataAction() signInGoogle(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.inProgress = true;
      }),
    );
    this.signIn('Google');
  }

  @DataAction() updateTokens(session: AuthSession): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.accessToken = session.tokens.accessToken;
        draft.idToken = session.tokens.idToken;
        draft.signedIn = true;
      }),
    );
  }

  signOut = async (): Promise<void> => {
    try {
      // needed to provide a clean localStorage environment for re-login!
      localStorage.removeItem('amplify-signin-with-hostedUI');

      return signOut();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  triggerRefresh = () => {
    this.refreshEmitter.emit();
  };

  private readonly checkActiveSession = (): void => {
    void fetchAuthSession()
      .then((session) => {
        if (!!session) {
          this.startPeriodicRefresh();
        }
      })
      .catch(() => {
        // no-op
      });
  };

  private readonly startPeriodicRefresh = (): void => {
    if (this.refreshInterval) {
      this.refreshInterval.unsubscribe();
    }
    this.refreshInterval = interval(this.#config.refreshInterval).subscribe(() => this.refreshEmitter.emit());
  };

  private readonly stopPeriodicRefresh = (): void => {
    if (this.refreshInterval) {
      this.refreshInterval.unsubscribe();
    }
  };

  private readonly signIn = (provider: 'Facebook' | 'Google'): void => {
    localStorage.setItem(POST_SIGN_IN_PATH, location.hash.replace('#/', ''));
    void signInWithRedirect({ provider });
  };
}
