<mat-card class="mat-elevation-z0">
  <h2>{{ 'ADD_CREDIT.ENTER_CUSTOM_AMOUNT' | translate }}</h2>
  <form [formGroup]="form" ngxsForm="customAmount.customAmountForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'ADD_CREDIT.AMOUNT' | translate }}</mat-label>
      <span matPrefix><mat-icon>euro</mat-icon>&nbsp;</span>
      <input matInput formControlName="amount" type="number" required />
      <mat-error *ngIf="form.controls.amount.errors?.required">{{
        'ADD_CREDIT.ERRORS.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="form.controls.amount.errors?.min">{{ 'ADD_CREDIT.ERRORS.MIN_AMOUNT' | translate }}</mat-error>
    </mat-form-field>
  </form>
  <h4>{{ 'ADD_CREDIT.MINIMUM_AMOUNT' | translate }}:&nbsp;{{ paymentConfig.minimumAmount | euro: true }}</h4>

  <mat-card-actions align="end">
    <button mat-button color="primary" [disabled]="!form.valid" (click)="startPayment()">
      {{ 'ADD_CREDIT.START_PAYMENT' | translate | uppercase }}
    </button>
    <button mat-button (click)="cancel()">
      {{ 'COMMON.CANCEL' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
