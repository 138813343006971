import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../models/page';

@Injectable({
  providedIn: 'root',
})
export abstract class BasePaginatedApiService<T> {
  getPage(pageIndex = 0, pageSize = 10, filters?: { [key: string]: unknown }): Observable<Page<T>> {
    const paramFilters = this.preProcessFilters(filters);

    const criteria = {
      ...paramFilters,
      page: pageIndex + 1,
      pageSize,
    };

    return this.doApiCall(
      new HttpParams({
        fromObject: criteria as {},
      }),
    );
  }

  abstract preProcessFilters(filters?: { [key: string]: unknown }): { [key: string]: unknown };

  abstract doApiCall(httpParams: HttpParams): Observable<Page<T>>;
}
