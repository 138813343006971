import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUtilsService } from '../../../../shared/services/api-utils.service';
import { AppConfigState } from '../../../../shared/store/app-config.state';
import { Machine } from '../../machine/models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class LocationDetailService {
  constructor(
    private readonly config: AppConfigState,
    private readonly http: HttpClient,
  ) {}

  getMachinesInfoForLocation(id: number, noLoadingIndicator = false): Observable<Array<Machine>> {
    // retrieve machine info for selected location

    return this.http.get<Array<Machine>>(`${this.config.apiPath}/locations/${id}/machines`, {
      headers: noLoadingIndicator ? ApiUtilsService.skipLoadingInterceptor() : undefined,
    });
  }
}
