import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { DateTime } from 'luxon';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { LocationState } from '../location/store/location.state';
import { SharedModule } from '../shared/shared.module';
import { AuthState } from '../shared/store/auth.state';
import { LangState } from '../shared/store/lang.state';
import { ProfileState } from './store/profile.state';

@Component({
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [NgxMatIntlTelInputComponent, ReactiveFormsModule, SharedModule, NgxsFormPluginModule],
})
export class ProfileComponent {
  form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    gender: new UntypedFormControl(),
    zipCode: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    yearOfBirth: new UntypedFormControl(),
    hiddenYearOfBirth: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl(''),
  });

  termsForm = new UntypedFormGroup({
    privacyTermsAccept: new UntypedFormControl(false, Validators.requiredTrue),
    termsAccept: new UntypedFormControl(false, Validators.requiredTrue),
  });

  constructor(
    private readonly auth: AuthState,
    private readonly fb: UntypedFormBuilder,
    private readonly lang: LangState,
    private readonly location: LocationState,
    readonly profile: ProfileState,
  ) {}

  saveProfile = (): void => {
    this.profile.updateProfile({
      ...this.form.value,
      id: this.profile.currentUserId,
      awsCognitoSub: this.auth.idToken.payload.sub,
      language: this.lang.lang.toUpperCase(),
      termsAccepted: true,
    });
  };

  cancel = (): void => {
    this.location.toHome();
  };

  closePicker = (selectedDate: DateTime, datepicker?: MatDatepicker<any>): void => {
    if (datepicker) {
      datepicker.close();
    }
    this.form.controls.hiddenYearOfBirth.setValue(selectedDate);
    this.form.controls.yearOfBirth.setValue(selectedDate.year);
    this.form.controls.yearOfBirth.markAsDirty();
  };
}
