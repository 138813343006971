import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthSession, fetchAuthSession, JWT } from 'aws-amplify/auth';
import { Observable, Observer, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { truthyFilter } from '../operators/truthy-filter';
import { AppConfigState, AppConfigStateModel } from '../store/app-config.state';
import { AuthState } from '../store/auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly auth: AuthState,
    private readonly config: AppConfigState,
  ) {}

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    // check if this is an API request
    if (req.url.indexOf('api') > -1) {
      // has to return an OBSERVABLE!
      return this.config.state$.pipe(
        map((state: AppConfigStateModel) => state.ready),
        // wait for AppConfigState to become ready
        truthyFilter(),
        switchMap(
          () =>
            new Observable((obs: Observer<any>) => {
              fetchAuthSession()
                .then((session: AuthSession) => {
                  obs.next(session);
                  obs.complete();
                })
                .catch(() => {
                  // no-op
                });
            }),
        ),
        // wait for AuthSession to exist and be populated
        filter((session: AuthSession) => !!session.tokens),
        switchMap((session: AuthSession) => {
          // Make sure session is set in store
          if (session && !this.auth.signedIn) {
            this.auth.updateTokens(session);
          }

          // MUDO Not needed anymore? Seems to be handled internally by Amplify
          // const accessTokenExpiration = session.getAccessToken().getExpiration();
          // const refreshToken = session.getRefreshToken();
          // const currentTimeSeconds = Math.round(+new Date() / 1000);
          //
          // if (accessTokenExpiration < currentTimeSeconds) {
          //   // Token expired, need to refresh
          //   return from(getCurrentUser()).pipe(
          //     switchMap(
          //       (user) =>
          //         new Observable((obs: Observer<any>) => {
          //           user.refreshSession(refreshToken, (err, data) => {
          //             if (err) {
          //               void signOut();
          //               obs.error(err);
          //             } else {
          //               this.auth.updateTokens(session);
          //               obs.next(data.getAccessToken());
          //               obs.complete();
          //             }
          //           });
          //         }),
          //     ),
          //   );
          // }

          return of(session.tokens.accessToken);
        }),
        switchMap((token: JWT) =>
          next.handle(
            req.clone({
              setHeaders: {
                Authorization: `Bearer ${token.toString()}`,
              },
            }),
          ),
        ),
      );
    }

    return next.handle(req);
  }
}
